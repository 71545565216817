<template>
  <div class="role-detail-component app-container">
    <DimensionChoice :programRow="currentRow" />
  </div>
</template>

<script>
import DimensionChoice from "./dimensionChoice.vue";
export default {
  components: {
    DimensionChoice,
  },
  created() {
    const { programName, programId, tenantId } = this.$route.query;
    this.currentRow = { programName, programId, tenantId };
    this.$eventBus.$emit("setDetailAlias", programName);
  },
  mounted() {},
};
</script>

<style>
</style>