<template>
  <div class="tenant-program-demension">
    <div class="header-line">
      <div class="tab-container">
        <span
          :class="[
            'single-tab',
            activeTab === item.dimensionCode && 'single-tab-active',
          ]"
          v-for="(item, index) in dimensionList"
          :key="index"
          @click="tabClickHandler(item)"
        >
          {{ item.dimensionName }}
        </span>
      </div>
      <div>
        <el-button>放弃更改</el-button>
        <el-button type="primary" @click="saveHandler">保存</el-button>
      </div>
    </div>
    <div class="items-container dimension-scroll" v-on:scroll="containerScroll">
      <div
        v-for="(item, index) in dimensionList"
        :key="index"
        class="single-item"
      >
        <DimesionItem
          :ref="`demession_ref_${item.dimensionCode}`"
          :key="index"
          :dimensionRow="item"
          @success="successHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getDimensionList } from "@/api/ruge/security/dimension";
import DimesionItem from "./DimesionItemNew";

export default {
  data() {
    return {
      activeTab: "dimensionCode",
      dimensionList: [],
      activeDimension: 0,
      successCount: 0,
    };
  },
  components: {
    DimesionItem,
  },
  props: {
    programRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    programId() {
      return this.programRow.programId;
    },
  },
  watch: {
    programId() {
      this.initDimension();
    },
  },
  created() {
    this.initDimension();
  },
  methods: {
    containerScroll() {
      const containerDom =
        document.getElementsByClassName("dimension-scroll")[0];
      const offsetTop = containerDom.scrollTop;
      if (
        containerDom.scrollTop + containerDom.clientHeight ==
        containerDom.scrollHeight
      ) {
        this.setActiveByIndex(this.dimensionList.length - 1);
        return;
      }
      // if (offsetTop < containerDom.offsetHeight - 50) {
      //   setActiveByIndex(this.dimensionList.length);
      //   return;
      // }
      // 计算子元素高度区间
      // [248,664,336]
      const heightList = [];
      this.dimensionList.forEach((item, index) => {
        const currentItem =
          this.$refs[`demession_ref_${item.dimensionCode}`][0].$el;
        const currentHeight = currentItem.offsetHeight;
        const preHeight = heightList[index - 1] || 0;
        heightList.push(currentHeight + preHeight);
      });
      console.log("heightList", heightList);
      for (let i = 0; i < heightList.length; i++) {
        if (offsetTop <= heightList[i]) {
          this.setActiveByIndex(i);
          break;
        }
      }
    },
    setActiveByIndex(index) {
      const currentCode = this.dimensionList.filter((item, i) => index === i)[0]
        .dimensionCode;
      this.activeTab = currentCode;
    },
    successHandler() {
      this.successCount++;
      if (this.successCount === this.dimensionList.length) {
        this.$message.success("保存成功！");
        this.successCount = 0;
      }
    },
    saveHandler() {
      this.$eventBus.$emit("getDemissionCheckList");
    },
    tabClickHandler({ dimensionCode }) {
      this.activeTab = dimensionCode;
      console.log("dimensionCode", dimensionCode);
      try {
        const containerDom =
          document.getElementsByClassName("dimension-scroll")[0];
        const targetDom = this.$refs[`demession_ref_${dimensionCode}`][0].$el;
        containerDom.scrollTo({
          top: targetDom.offsetTop - 90,
          // behavior: "smooth",
        });
      } catch (error) {}
    },
    initDimension() {
      getDimensionList().then((list) => {
        list.map((r) => {
          r.dimensionName = this.$t(r.dimensionName);
          r.programId = this.programRow.programId;
          r.tenantId = this.programRow.tenantId;
        });
        this.dimensionList = list;
        this.activeTab = this.dimensionList[0].dimensionCode;
        console.log("this.dimensionList", this.dimensionList);
        list.length > 0 && this.choiceDimension(list[0]);
      });
    },
    choiceDimension(item) {
      this.$set(item, "load", true);
    },
  },
};
</script>
<style lang="less" scoped>
.tenant-program-demension {
  height: 100%;
  .header-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f1f4f6;
    padding-bottom: 8px;
    .tab-container {
      height: 35px;
      line-height: 35px;
      display: flex;
      .single-tab {
        font-size: 14px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #5d687c;
        cursor: pointer;
        position: relative;
      }
      .single-tab + .single-tab {
        margin-left: 50px;
      }
      .single-tab-active {
        color: #252d3d;
        &::after {
          content: "";
          display: inline-block;
          width: 30px;
          height: 2px;
          background: #2a61ff;
          border-radius: 7px;
          position: absolute;
          bottom: -9px;
          left: calc(50% - 15px);
        }
      }
    }
  }
  .items-container {
    height: calc(100% - 45px);
    padding: 30px 0;
    overflow: auto;
  }
}
</style>