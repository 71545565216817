<template>
  <div class="dimension-component-new">
    <div class="title-line">
      <span>
        {{ dimensionRow.dimensionName }}
      </span>
      <span class="checkbox-span">
        <el-checkbox v-model="checkAll"
          >拥有所有{{ dimensionRow.dimensionName }}权限</el-checkbox
        >
      </span>
    </div>
    <div v-loading="tableLoading">
      <el-row :gutter="20" class="filter-line">
        <el-col :span="4">
          <el-input
            suffix-icon="el-icon-search"
            maxlength="50"
            width
            clearable
            v-model="left.page.param.searchPhrase"
            placeholder="请输入搜索内容"
            style="width: 100%"
            @input="leftFilter"
          ></el-input>
        </el-col>
      </el-row>
      <div class="filter-line"></div>
      <el-table
        :data="left.waitCheckList"
        @select="leftSelection"
        @select-all="leftSelection"
        border
        ref="leftTable"
        style="width: 100%; margin-bottom: 0px"
      >
        <el-table-column type="selection" align="center" width="50">
        </el-table-column>
        <el-table-column
          v-for="(item, key) in left.tableTitle"
          :key="key"
          :prop="item.value"
          :label="item.display"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <pagination
        v-show="left.page.total > 0"
        :total="left.page.total"
        layout="prev, pager, next"
        :page.sync="left.page.param.current"
        :limit.sync="left.page.param.rowCount"
        @pagination="getLeftPageList"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { debounce } from "lodash";
let manager = require("@/api/ruge/security/dimension");
let interConst = {
  checkAllValue: "@ALLCONDITION@",
  checkAllDisplay: "ALL",
};
export default {
  name: "dimension-component-new",
  components: {
    Pagination,
  },
  props: {
    dimensionRow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkAllId: null,
      checkAll: false,
      dataList: [],
      tableTitle: [],
      tableLoading: false,
      left: {
        //左侧列表
        waitCheckList: [], //待选列表
        checkList: [], //已选列表
        tableTitle: [], //表头 {display: '日期',value: 'date',width: 100}
        service: "", //服务地址
        param: { valueField: "", displayField: "" }, //提交的value和display的字段
        page: {
          total: 0,
          param: {
            programId: null,
            dimensionCode: "",
            current: 1,
            rowCount: 10,
            searchPhrase: null, //搜索绑定参数key
          },
        },
      },
    };
  },
  async created() {
    this.dissectUrl();
    this.getCheckAllDimension();
    await this.reqLeftDimension();
    this.reqRightDimension(true);
  },
  mounted() {
    this.$eventBus.$on("getDemissionCheckList", async () => {
      this.tableLoading = true;
      await this.tableSaveHandler();
      await this.checkAllSaveHandler();
      this.tableLoading = false;
      this.$emit("success");
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("getDemissionCheckList");
  },
  methods: {
    leftFilter: debounce(function () {
      this.left.page.param.current = 1;
      this.reqLeftDimension(true);
    }, 500), //左搜索过滤
    checkAllSaveHandler() {
      return new Promise((resolve) => {
        const newVal = this.checkAll;
        if (newVal) {
          //选中
          if (!this.checkAllId) {
            //添加
            let params = {
              programId: this.dimensionRow.programId,
              dimensionCode: this.dimensionRow.dimensionCode,
              sourceApp: this.dimensionRow.sourceApp,
              itemList: [
                {
                  value: interConst.checkAllValue,
                  displayValue: interConst.checkAllDisplay,
                  dimensionCode: this.dimensionRow.dimensionCode,
                  sourceApp: this.dimensionRow.sourceApp,
                },
              ],
            };
            manager._tenant_createProgramDimension(params).then(() => {
              this.getCheckAllDimension();
              resolve();
            });
          }
          resolve();
        } else {
          //未选中
          if (this.checkAllId) {
            //移除
            manager
              ._tenant_deleteProgramDimension({
                programId: this.dimensionRow.programId,
                itemList: [{ programItemId: this.checkAllId }],
              })
              .then(() => {
                this.checkAllId = null;
                resolve();
              });
          }
          resolve();
        }
      });
    },
    tableSaveHandler() {
      return new Promise((resolve) => {
        let itemList = this.left.checkList.map((c) => {
          return {
            value: c[this.left.param.valueField],
            displayValue: c[this.left.param.displayField],
            dimensionCode: this.dimensionRow.dimensionCode,
            sourceApp: this.dimensionRow.sourceApp,
          };
        });
        let params = {
          programId: this.dimensionRow.programId,
          dimensionCode: this.dimensionRow.dimensionCode,
          sourceApp: this.dimensionRow.sourceApp,
          itemList: itemList,
        };
        manager._tenant_createProgramDimension(params).then(() => {
          this.reqRightDimension(false);
          resolve();
        });
      });
    },
    reqRightDimension(freshChecked) {
      const params = {
        programId: this.dimensionRow.programId,
        dimensionCode: this.dimensionRow.dimensionCode,
        sourceApp: this.dimensionRow.sourceApp,
        searchPhrase: null,
        current: 1,
        rowCount: 9999,
      };
      manager._tenant_getCheckDimension(params).then((req) => {
        this.left.checkList = req.rows.map((item) => {
          item[this.left.param.valueField] = item.value;
          item[this.left.param.displayField] = item.displayValue;
          return item;
        });
        this.$nextTick(() => {
          freshChecked && this.toggleCheck("left");
        });
      });
    },
    getLeftPageList() {
      //左分页
      this.reqLeftDimension(true);
    },
    getCheckAllDimension() {
      let params = {
        programId: this.dimensionRow.programId,
        dimensionCode: this.dimensionRow.dimensionCode,
        sourceApp: this.dimensionRow.sourceApp,
      };
      manager._tenant_getCheckAllDimension(params).then((r) => {
        if (r && r.value == interConst.checkAllValue) {
          //全选状态
          this.checkAll = true;
          this.checkAllId = r.programItemId;
        }
      });
    },
    leftSelection(checkRows, checkRow) {
      console.log("checkRows, checkRow", checkRows, checkRow);
      this.toggeleCheckData("left", checkRows, checkRow);
    },
    toggeleCheckData(panel, checkRows, checkRow) {
      let checkActive = 0;
      if (checkRows.length == 0 && !checkRow) {
        //取消全选
        checkActive = -1;
      }

      if (checkRows.length > 0 && !checkRow) {
        //全选选中
        checkActive = 1;
      }

      let refTable = this.$refs.leftTable;
      let valueField = this.left.param.valueField;
      let check = this.left;
      if (panel == "right") {
        refTable = this.$refs.rightTable;
        check = this.right;
        valueField = "value";
      }

      if (checkRow) {
        //单选
        //单选取消
        checkActive = -2;
        for (let i = 0; i < checkRows.length; i++) {
          if (checkRows[i][valueField] == checkRow[valueField]) {
            //单选选中
            checkActive = 2;
          }
        }
      }

      let resumeCheckList = [];
      if (checkActive == -1) {
        //取消全选事件
        for (let i = 0; i < check.checkList.length; i++) {
          let delFlag = false;
          for (let j = 0; j < refTable.data.length; j++) {
            if (
              check.checkList[i][valueField] == refTable.data[j][valueField]
            ) {
              delFlag = true;
            }
          }
          if (!delFlag) {
            resumeCheckList.push(check.checkList[i]);
          }
        }
        check.checkList = resumeCheckList;
      } else if (checkActive == -2) {
        //取消单选事件
        check.checkList.splice(
          check.checkList.findIndex(
            (item) => item[valueField] == checkRow[valueField]
          ),
          1
        );
      } else if (checkActive == 1 || checkActive == 2) {
        let newRows = this.filterRep(check.checkList, checkRows, valueField);
        if (newRows && newRows.length > 0) {
          check.checkList = check.checkList.concat(newRows);
        }
      }
    },
    filterRep(dataList, row, valueField) {
      let newRow = [];
      if (row) {
        for (let j = 0; j < row.length; j++) {
          let n = true;
          for (let i = 0; i < dataList.length; i++) {
            if (dataList[i][valueField] == row[j][valueField]) {
              n = false;
              break;
            }
          }
          if (n) {
            newRow.push(row[j]);
          }
        }
      }
      return newRow;
    },
    dissectUrl() {
      //解析url获取表头
      let dimensionUrl = this.dimensionRow.dimensionUrl;
      let urls = dimensionUrl.split("?");
      if (!urls || !urls[1]) {
        return;
      }
      this.left.service = urls[0];
      let parameters = urls[1].split("&");
      for (let i = 0; i < parameters.length; i++) {
        if (!parameters[i]) continue;
        let paramkv = parameters[i].split("=");
        if (!paramkv || !paramkv[0] || !paramkv[1]) continue;
        if (paramkv[0] == "valueField") {
          this.left.param.valueField = paramkv[1];
          //default_record[paramkv[1]] = "@ALLCONDITION@";
          continue;
        } else if (paramkv[0] == "displayField") {
          this.left.param.displayField = paramkv[1];
          //default_record[paramkv[1]] = "All";
          continue;
        }
        //左侧表头
        this.left.tableTitle.push({ display: paramkv[1], value: paramkv[0] });
      }
    },
    reqLeftDimension(checkFlag) {
      this.tableLoading = true;
      return new Promise((resolse) => {
        this.left.page.param.searchPhrase = this.left.page.param.searchPhrase
          ? this.left.page.param.searchPhrase
          : null;
        manager
          .reqConsumeDimension(
            this.left.service,
            Object.assign(
              { tenantId: this.dimensionRow.tenantId },
              this.left.page.param
            )
          )
          .then((req) => {
            this.left.waitCheckList = req.rows;
            this.left.page.total = req.total;
            this.$nextTick(() => {
              checkFlag && this.toggleCheck("left");
            });
          })
          .finally(() => {
            resolse();
            this.tableLoading = false;
          });
      });
    },
    toggleCheck(panel) {
      let refTable = this.$refs.leftTable;
      let valueField = this.left.param.valueField;
      let table = this.left;
      if (panel == "right") {
        refTable = this.$refs.rightTable;
        table = this.right;
        valueField = "value";
      }
      table.waitCheckList.forEach((w) => {
        for (let i = 0; i < table.checkList.length; i++) {
          if (w[valueField] == table.checkList[i].value) {
            refTable.toggleRowSelection(w);
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dimension-component-new {
  margin-bottom: 40px;
  .title-line {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #252d3d;
    position: relative;
    padding-left: 8px;
    margin-bottom: 20px;
    .checkbox-span {
      margin-left: 15px;
    }
    &::before {
      content: "";
      width: 3px;
      height: 12px;
      background: #2a61ff;
      border-radius: 7px;
      position: absolute;
      left: 0;
      top: calc(50% - 6px);
    }
  }
  .filter-line {
    margin-bottom: 15px;
  }
}
</style>