var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tenant-program-demension" }, [
    _c("div", { staticClass: "header-line" }, [
      _c(
        "div",
        { staticClass: "tab-container" },
        _vm._l(_vm.dimensionList, function (item, index) {
          return _c(
            "span",
            {
              key: index,
              class: [
                "single-tab",
                _vm.activeTab === item.dimensionCode && "single-tab-active",
              ],
              on: {
                click: function ($event) {
                  return _vm.tabClickHandler(item)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(item.dimensionName) + "\n      ")]
          )
        }),
        0
      ),
      _c(
        "div",
        [
          _c("el-button", [_vm._v("放弃更改")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveHandler } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "items-container dimension-scroll",
        on: { scroll: _vm.containerScroll },
      },
      _vm._l(_vm.dimensionList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "single-item" },
          [
            _c("DimesionItem", {
              key: index,
              ref: `demession_ref_${item.dimensionCode}`,
              refInFor: true,
              attrs: { dimensionRow: item },
              on: { success: _vm.successHandler },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }